/* base styles */
body {
  font-family: Fira Sans Condensed, sans-serif;
  margin: 0;
  font-size: 1.1em;
}
ul, li, p, h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
}
.nav_icon {
  margin-left: 650px;
  height: 32px;
  width: 32px;
  pointer-events: none;
}
.nav_icon_back {
  margin-left: 5px;
  height: 32px;
  width: 32px;
  pointer-events: none;
}
.btn {
  background: none;
  border: 2px solid #4C92E6;
  padding: 6px 12px;
  border-radius: 4px;
  color: #4C92E6;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}
.btn:hover {
  background: #4C92E6;
  color: #fff;
}
/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #4C92E6;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  right: 65%;
  z-index: 0;
}

.tooltip .tooltiptextfront {
  visibility: hidden;
  width: 120px;
  background-color: #4C92E6;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  right: 5%;
  z-index: 0;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip:hover .tooltiptextfront {
  visibility: visible;
}