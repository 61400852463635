@use "../utils_form" as *;

.flex_col {
  display: flex;
  flex-direction: column;
  gap: 17px 0;
  width: 98%;
  padding-top: 17px;
  margin-left: 1%;
}

.deviceInfoFlexColumn {
  display: flex;
  flex-direction: column;
  gap: 17px 0;
}

.contentBoxCreateTRK {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 25.96%;
  width: 624px;
  max-width: 624px;
  background-color: rgb(252 252 252);
  border-radius: 10px;
  outline: 1px solid rgb(0 0 0 / 9.8%);
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 25%);
}

.flexContainerCreateCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 33px 30px 55px;

  @include xs {
    margin: 33px 16px 55px;
  }

  @include tn {
    margin: 33px 8px 50px;
  }
}

.flexContainerCreateCol_login {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 33px 30px 55px;

  @include xs {
    margin: 33px 16px 55px;
  }

  @include tn {
    margin: 33px 8px 50px;
  }
}

.createTRKTitle {
  width: 164.0078125px;
  max-width: 85%;
  font-style: normal;
  font: 300 24px/1.2 Montserrat, Helvetica, Arial, serif;
  color: rgb(0 0 0);
  padding-bottom: 17px;

  @include md {
    align-items: flex-start;
    font-size: 22px;
  }

  @include xxs {
    font-size: 20px;
  }
}

.flexContainerNameEntry {
  display: flex;
  flex-direction: column;
  gap: 8px 0;
  width: 100%;
}

.infoTRKName {
  font: 600 12px/1.5 "Noto Sans", Helvetica, Arial, serif;
  color: rgb(102 102 102);
  text-align: left;
  margin-left: 2.5%;
}

.infoTRKName_login {
  font: 600 12px/1.5 "Noto Sans", Helvetica, Arial, serif;
  color: rgb(102 102 102);
  text-align: left;
  margin-left: 0%;
  padding-top: 10px;
}

.content_box4 {
  display: flex;
  flex-direction: column;
  background-color: rgb(255 255 255);
  border-radius: 8px;
  outline: 1px solid rgb(204 204 204);
  outline-offset: -0.5px;
}

.content_box4:hover {
  outline: 2px solid rgb(69 132 208);
}

.content_box4_login {
  display: flex;
  flex-direction: column;
  background-color: rgb(255 255 255);
  border-radius: 8px;
  outline: 1px solid rgb(204 204 204);
  outline-offset: -0.5px;
  width: 60%;
}

.content_box4_login:hover {
  outline: 2px solid rgb(69 132 208);
}

.content_box4_input {
  display: flex;
  flex-direction: column;
  background-color: rgb(255 255 255);
  border-radius: 8px;
  outline: 1px solid rgb(204 204 204);
  border: 1px solid rgb(204 204 204);
  outline-offset: -0.5px;
  font: 400 16px/1.5 "Noto Sans", Helvetica, Arial, serif;
  color: rgb(102 102 102);
  padding: 14px;
}

.content_box4_input_login {
  display: flex;
  flex-direction: column;
  background-color: rgb(255 255 255);
  border-radius: 8px;
  outline: 1px solid rgb(204 204 204);
  border: 1px solid rgb(204 204 204);
  outline-offset: -0.5px;
  font: 400 16px/1.5 "Noto Sans", Helvetica, Arial, serif;
  color: rgb(102 102 102);
  padding: 10px;
}

.content_box76 {
  display: flex;
  flex-direction: column;
  background-color: rgb(255 255 255);
  border-radius: 8px;
  outline: 1px solid rgb(204 204 204);
  outline-offset: -0.5px;
  padding: 15px;
}

.content_box76:hover {
  outline: 2px solid rgb(69 132 208);
}

.content_box77 {
  display: flex;
  flex-direction: column;
  background-color: rgb(255 255 255);
  border-radius: 8px;
  outline: 1px solid rgb(204 204 204);
  outline-offset: -0.5px;
  padding: 15px;
}

.content_box77:hover {
  outline: 2px solid rgb(69 132 208);
}
.contentBoxNameInstructions {
  display: flex;
  justify-content: space-between;
  gap: 0 10px;
  margin: 16px;

  @include tn {
    column-gap: 8px;
    margin: 16px 8px;
  }
}

.highlightTRKName {
  font: 400 16px/1.5 "Noto Sans", Helvetica, Arial, serif;
  color: rgb(102 102 102);
}

.imageEntryTRKName {
  width: 24px;
  object-fit: cover;
}

.flexContainerTrackerSelection {
  display: flex;
  flex-direction: column;
  margin: 30px 0 0;
  width: 100%;
}

.infoSelectTracker {
  font: 600 12px/1.5 "Noto Sans", Helvetica, Arial, serif;
  color: rgb(102 102 102);
  text-align: left;
  margin-left: 2.5%;
}

.contentBoxTrackerName {
  display: flex;
  flex-direction: column;
  margin: 8px 0 0;
  background-color: rgb(255 255 255);
  border-radius: 8px 8px 0 0;
  outline: 2px solid rgb(69 132 208);
  outline-offset: -1px;
}

.flex_row {
  display: flex;
  justify-content: space-between;
  gap: 0 10px;
  margin: 16px;

  @include tn {
    column-gap: 8px;
    margin: 16px 8px;
  }
}

.highlightTrackerName {
  font: 400 16px/1.5 "Noto Sans", Helvetica, Arial, serif;
  color: rgb(51 51 51);
}

.imageTrackerName {
  width: 24px;
  object-fit: cover;
}

.contentBoxTrackerOptions {
  display: flex;
  flex-direction: column;
  background-color: rgb(82 159 251);
  outline: 1px solid rgb(204 204 204);
  outline-offset: -0.5px;
}

.flexRowTrackerOptions {
  display: flex;
  justify-content: space-between;
  gap: 0 10px;
  margin: 16px;

  @include tn {
    column-gap: 8px;
    margin: 16px 8px;
  }
}

.highlightTrackerOption1 {
  font: 400 16px/1.5 "Noto Sans", Helvetica, Arial, serif;
  color: rgb(255 255 255);
}

.imageTrackerOption1 {
  width: 24px;
  object-fit: cover;
}

.contentBoxSensorOptions {
  display: flex;
  flex-direction: column;
  background-color: rgb(255 255 255);
  outline: 1px solid rgb(204 204 204);
  outline-offset: -0.5px;
}

.flexRowSensorOptions {
  display: flex;
  justify-content: space-between;
  gap: 0 10px;
  margin: 16px;

  @include tn {
    column-gap: 8px;
    margin: 16px 8px;
  }
}

.highlightSensorName3298 {
  font: 400 16px/1.5 "Noto Sans", Helvetica, Arial, serif;
  color: rgb(102 102 102);
}

.imageSensor3298 {
  width: 24px;
  object-fit: cover;
}

.contentBoxSensorOptions2 {
  display: flex;
  flex-direction: column;
  background-color: rgb(255 255 255);
  border-radius: 0 0 8px 8px;
  outline: 1px solid rgb(204 204 204);
  outline-offset: -0.5px;
}

.flexRowSensorOptions2 {
  display: flex;
  justify-content: space-between;
  gap: 0 10px;
  margin: 16px;

  @include tn {
    column-gap: 8px;
    margin: 16px 8px;
  }
}

.highlightSensorName0005 {
  font: 400 16px/1.5 "Noto Sans", Helvetica, Arial, serif;
  color: rgb(102 102 102);
}

.imageSensor0005 {
  width: 24px;
  object-fit: cover;
}

.flexContainerStartAddress {
  display: flex;
  flex-direction: column;
  gap: 8px 0;
  margin: 30px 0 0;
  width: 100%;
}

.infoStartAddress {
  font: 600 12px/1.5 "Noto Sans", Helvetica, Arial, serif;
  color: rgb(102 102 102);
  text-align: left;
  margin-left: 2.5%;
}

.contentBoxStartAddress {
  display: flex;
  flex-direction: column;
  background-color: rgb(255 255 255);
  border-radius: 8px;
  outline: 1px solid rgb(204 204 204);
  outline-offset: -0.5px;
}

.contentBoxStartAddress:hover {
  outline: 2px solid rgb(69 132 208);
}

.flexRowStartAddress {
  display: flex;
  justify-content: space-between;
  gap: 0 10px;
  margin: 16px;

  @include tn {
    column-gap: 8px;
    margin: 16px 8px;
  }
}

.highlightStartAddressInstruction {
  font: 400 16px/1.5 "Noto Sans", Helvetica, Arial, serif;
  color: rgb(102 102 102);
}

.imageStartAddress {
  width: 24px;
  object-fit: cover;
}

.infoStartAddressDetail {
  font: 400 12px/1.5 "Noto Sans", Helvetica, Arial, serif;
  color: rgb(102 102 102);
  text-align: left;
  margin-left: 2.5%;
}

.flexContainerDestinationAddress {
  display: flex;
  flex-direction: column;
  gap: 8px 0;
  margin: 29px 0 0;
  width: 100%;
}

.infoDestinationAddress {
  font: 600 12px/1.5 "Noto Sans", Helvetica, Arial, serif;
  color: rgb(102 102 102);
  text-align: left;
  margin-left: 2.5%;
}

.contentBoxDestinationAddress {
  display: flex;
  flex-direction: column;
  background-color: rgb(255 255 255);
  border-radius: 8px;
  outline: 2px solid rgb(69 132 208);
  outline-offset: -1px;
}

.flexRowDestinationAddress {
  display: flex;
  justify-content: space-between;
  gap: 0 10px;
  margin: 16px;

  @include tn {
    column-gap: 8px;
    margin: 16px 8px;
  }
}

.highlightDestinationAddressInstruction {
  font: 400 16px/1.5 "Noto Sans", Helvetica, Arial, serif;
  color: rgb(51 51 51);
}

.imageDestinationAddress {
  width: 24px;
  object-fit: cover;
}

.infoDestinationAddressDetail {
  font: 400 12px/1.5 "Noto Sans", Helvetica, Arial, serif;
  color: rgb(102 102 102);
  text-align: left;
  margin-left: 2.5%;
}

.flexContainerArrivalDateTime {
  display: flex;
  flex-direction: column;
  gap: 8px 0;
  margin: 31px 0 0;
  width: 100%;
}

.infoArrivalDateTime {
  font: 600 12px/1.5 "Noto Sans", Helvetica, Arial, serif;
  color: rgb(102 102 102);
  text-align: left;
  margin-left: 2.5%;
}

.contentBoxArrivalDateTime {
  display: flex;
  flex-direction: column;
  background-color: rgb(255 255 255);
  border-radius: 8px;
  outline: 1px solid rgb(204 204 204);
  outline-offset: -0.5px;
}

.flexRowArrivalDateTime {
  display: flex;
  justify-content: space-between;
  gap: 0 10px;
  margin: 16px;

  @include tn {
    column-gap: 8px;
    margin: 16px 8px;
  }
}

.highlightDateTimeInstruction {
  font: 400 16px/1.5 "Noto Sans", Helvetica, Arial, serif;
  color: rgb(102 102 102);
}

.imageDateTimeSelection {
  width: 24px;
  object-fit: cover;
}

.flexContainerTemperatureAlerts {
  display: flex;
  gap: 0 16px;
  margin: 55px 0 0;
  width: 100%;

  @include tn {
    column-gap: 8px;
    margin: 50px 0 0;
  }
}

.imageTemperatureAlerts {
  width: 49px;
  object-fit: cover;
}

.highlightTemperatureAlertsText {
  font: 400 16px/1.5 "Noto Sans", Helvetica, Arial, serif;
  color: rgb(102 102 102);
  margin-top: 6.5px;
}

.flexContainerHighTempAlertSettings {
  display: flex;
  flex-direction: column;
  gap: 8px 0;
  margin: 45px 0 0;
  width: 100%;
}

.infoHighTempAlert {
  font: 600 12px/1.5 "Noto Sans", Helvetica, Arial, serif;
  color: rgb(102 102 102);
  text-align: left;
  margin-left: 2.5%;
}

.contentBoxHighTempSettings {
  display: flex;
  flex-direction: column;
  background-color: rgb(255 255 255);
  border-radius: 8px;
  outline: 1px solid rgb(204 204 204);
  outline-offset: -0.5px;
}

.contentBoxHighTempSettings:hover {
  outline: 2px solid rgb(69 132 208);
}

.contentBoxHighTempSettingsOff {
  display: flex;
  flex-direction: column;
  background-color: rgb(244 244 244);
  border-radius: 8px;
  outline: 1px solid rgb(204 204 204);
  outline-offset: -0.5px;
}

.lock_icon {
  height: 22px;
  width: 22px;
  pointer-events: none;
}

.flexRowHighTempSettings {
  display: flex;
  justify-content: space-between;
  gap: 0 10px;
  margin: 16px;

  @include tn {
    column-gap: 8px;
    margin: 16px 8px;
  }
}

.highlightHighTempInstruction {
  font: 400 16px/1.5 "Noto Sans", Helvetica, Arial, serif;
  color: rgb(102 102 102);
}

.imageHighTempSettings {
  width: 24px;
  object-fit: cover;
}

.infoHighTempDetails {
  font: 400 12px/1.5 "Noto Sans", Helvetica, Arial, serif;
  color: rgb(102 102 102);
  text-align: left;
  margin-left: 2.5%;
}

.flexContainerLowTempAlertSettings {
  display: flex;
  flex-direction: column;
  gap: 8px 0;
  margin: 28px 0 0;
  width: 100%;
}

.infoLowTempAlert {
  font: 600 12px/1.5 "Noto Sans", Helvetica, Arial, serif;
  color: rgb(102 102 102);
  text-align: left;
  margin-left: 2.5%;
}

.contentBoxLowTempSettings {
  display: flex;
  flex-direction: column;
  background-color: rgb(255 255 255);
  border-radius: 8px;
  outline: 2px solid rgb(235 87 87);
  outline-offset: -1px;
}

.flexRowLowTempSettings {
  display: flex;
  justify-content: space-between;
  gap: 0 10px;
  margin: 16px;

  @include tn {
    column-gap: 8px;
    margin: 16px 8px;
  }
}

.highlightLowTempInstruction {
  font: 400 16px/1.5 "Noto Sans", Helvetica, Arial, serif;
  color: rgb(51 51 51);
}

.imageLowTempSettings {
  width: 24px;
  object-fit: cover;
}

.infoLowTempAlertDetails {
  font: 400 12px/1.5 "Noto Sans", Helvetica, Arial, serif;
  color: rgb(235 87 87);
  text-align: left;
  margin-left: 2.5%;
}

.flexContainerHumidityAlerts {
  display: flex;
  gap: 0 16px;
  margin: 45px 0 0;
  width: 100%;

  @include tn {
    column-gap: 8px;
  }
}

.imageHumidityAlerts {
  width: 49px;
  object-fit: cover;
}

.highlightHumidityAlertsText {
  font: 400 16px/1.5 "Noto Sans", Helvetica, Arial, serif;
  color: rgb(102 102 102);
  margin-top: 6.5px;
}

.flexContainerHighHumidityAlertSettings {
  display: flex;
  flex-direction: column;
  gap: 8px 0;
  margin: 45px 0 0;
  width: 100%;
}

.infoHighHumidityAlert {
  font: 600 12px/1.5 "Noto Sans", Helvetica, Arial, serif;
  color: rgb(102 102 102);
  text-align: left;
  margin-left: 2.5%;
}

.contentBoxHighHumiditySettings {
  display: flex;
  flex-direction: column;
  background-color: rgb(244 244 244);
  border-radius: 8px;
  outline: 1px solid rgb(204 204 204);
  outline-offset: -0.5px;
}

.flexRowHighHumiditySettings {
  display: flex;
  justify-content: space-between;
  gap: 0 10px;
  margin: 16px 24px 16px 16px;

  @include xs {
    margin: 16px;
  }

  @include tn {
    column-gap: 8px;
    margin: 16px 8px;
  }
}

.highlightHighHumidityInstruction {
  font: 400 16px/1.5 "Noto Sans", Helvetica, Arial, serif;
  color: rgb(102 102 102);
}

.imageHighHumiditySettings {
  margin: 4px 0;
  width: 16px;
  object-fit: cover;
}

.info1 {
  font: 400 12px/1.5 "Noto Sans", Helvetica, Arial, serif;
  color: rgb(102 102 102);
  text-align: left;
  margin-left: 2.5%;
}

.flexContainerLowHumidityAlertSettings {
  display: flex;
  flex-direction: column;
  gap: 8px 0;
  margin: 28px 0 0;
  width: 100%;
}

.infoLowHumidityAlert {
  font: 600 12px/1.5 "Noto Sans", Helvetica, Arial, serif;
  color: rgb(102 102 102);
  text-align: left;
  margin-left: 2.5%;
}

.contentBoxLowHumiditySettings {
  display: flex;
  flex-direction: column;
  background-color: rgb(244 244 244);
  border-radius: 8px;
  outline: 1px solid rgb(204 204 204);
  outline-offset: -0.5px;
}

.flexRowLowHumiditySettings {
  display: flex;
  justify-content: space-between;
  gap: 0 10px;
  margin: 16px 24px 16px 16px;

  @include xs {
    margin: 16px;
  }

  @include tn {
    column-gap: 8px;
    margin: 16px 8px;
  }
}

.highlightLowHumidityInstruction {
  font: 400 16px/1.5 "Noto Sans", Helvetica, Arial, serif;
  color: rgb(102 102 102);
}

.imageLowHumiditySettings {
  margin: 4px 0;
  width: 16px;
  object-fit: cover;
}

.flexContainerActionButtons {
  display: flex;
  justify-content: space-between;
  gap: 0 10px;
  margin: 70px 0 0;
  width: 100%;

  @include tn {
    column-gap: 8px;
    margin: 50px 0 0;
  }
}

.flexContainerActionButtons_login {
  display: flex;
  justify-content: center;
  gap: 0 10px;
  margin: 30px 0 0;
  width: 100%;

  @include tn {
    column-gap: 8px;
    margin: 50px 0 0;
  }
}

.btnCancelTRK {
  display: flex;
  justify-content: center;
  min-width: 172px;
  font: 400 16px/1.5 "Noto Sans", Helvetica, Arial, serif;
  color: rgb(76 146 230);
  text-align: center;
  background-color: rgb(255 255 255);
  border-radius: 8px;
  padding: 16px 8px;
  outline: 1px solid rgb(76 146 230);
  outline-offset: -0.5px;
  cursor: pointer;
}

.btnCancelTRK:hover {
  background: #4C92E6;
  color: #fff;
}

.btnCreateTRK {
  display: flex;
  justify-content: center;
  min-width: 172px;
  font: 400 16px/1.5 "Noto Sans", Helvetica, Arial, serif;
  color: rgb(255 255 255);
  text-align: center;
  background-color: rgb(76 146 230);
  border-radius: 8px;
  padding: 16px 8px;
  cursor: pointer;
}

.btnCreateTRK:hover {
  background: #529ffb;
  color: #fff;
}