/* navbar styles */
.navbar {
    width: 100%;
    border-bottom: 1px rgba(0, 0, 0, 0.30);
    background: #FCFCFC;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.navbar ul {
    display: flex;
    margin: 0 auto;
    max-width: 1440px;
    align-items: center;
}
.navbar li {
    margin-left: 5px;
    text-align: right;
}
.title {
    margin-right: auto;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 1.2em;
    text-align: right;
}
.navbar button, .navbar a {
    margin-left: 20px;
}
.navbar a {
    color: #333;
    text-decoration: none;
}
