$mq-breakpoints: (tn: 384, xxs: 480, xs: 576, sm: 768, md: 992,
        lg: 1200, xl: 1400, xxl: 1920, xxxl: 3000, max: 100000);

@function breakpoint-max($name, $breakpoints: $mq-breakpoints) {
  $max: map-get($breakpoints, $name);
  @return if($max and $max > 0, $max - 1, null);
}

@function breakpoint-min($name, $breakpoints: $mq-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

@mixin tn {
  $max: breakpoint-max(tn, $mq-breakpoints);
  @media(max-width: #{$max}px) {
    @content;
  }
}

@mixin xxs {
  $max: breakpoint-max(xxs, $mq-breakpoints);
  @media(max-width: #{$max}px) {
    @content;
  }
}

@mixin xs {
  $max: breakpoint-max(xs, $mq-breakpoints);
  @media(max-width: #{$max}px) {
    @content;
  }
}

@mixin sm {
  $max: breakpoint-max(sm, $mq-breakpoints);
  @media(max-width: #{$max}px) {
    @content;
  }
}

@mixin md {
  $max: breakpoint-max(md, $mq-breakpoints);
  @media(max-width: #{$max}px) {
    @content;
  }
}

@mixin lg {
  $max: breakpoint-max(lg, $mq-breakpoints);
  @media(max-width: #{$max}px) {
    @content;
  }
}