@use "../utils" as *;

.flex_col {
    display: flex;
    flex-direction: column;
    gap: 17px 0;
    width: 98%;
    padding-top: 17px;
    margin-left: 1%;
}

.deviceInfoFlexColumn {
    display: flex;
    flex-direction: column;
    gap: 17px 0;
}

.deviceInfoContentBox {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: rgb(235 13 13 / 20.0%);
    border-radius: 10px;
    outline: 1px solid rgb(0 0 0 / 9.8%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.deviceInfoContentBoxGreen {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: rgb(130 204 133 / 45.0%);
    border-radius: 10px;
    outline: 1px solid rgb(0 0 0 / 9.8%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.deviceInfoContentBoxMapOutline {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: rgb(252 252 252);
    border-radius: 10px;
    outline: 1px solid rgb(0 0 0 / 9.8%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding-bottom: 15px;
    padding-top: 15px;
}

.deviceInfoContentBoxMap {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: rgb(252 252 252);
    border-radius: 10px;
    outline: 1px solid rgb(0 0 0 / 9.8%);
    height: 500px;
    width: 98%;
    margin-left: 1%;
}

.deviceInfoFlexRow {
    position: relative;
    display: flex;
    gap: 0 22px;
    margin: 28px 21px 30px 26px;

    @include lg {
        flex-direction: column;
        align-items: center;
        gap: 30px 22px;
    }

    @include xs {
        margin: 28px 16px 30px;
    }

    @include tn {
        margin: 28px 8px 30px;
    }
}

.content_box78 {
    display: flex;
    flex-direction: column;
    background-color: rgb(255 255 255);
    border-radius: 8px;
    outline: 1px solid rgb(204 204 204);
    outline-offset: -0.5px;
    padding: 20px;
}

.content_box76:hover {
    outline: 2px solid rgb(69 132 208);
}

.deviceRectangle_good {
    margin: 3px 0 1px;
    width: 25px;
    min-height: 25px;
    background-color: rgb(130 204 133);
    border-radius: 13px;
    outline: 2px solid rgb(0 0 0 / 20%);
    outline-offset: -2px;
    filter: blur(2px);

    @include lg {
        margin: 0;
        width: 100%;
    }
}

.deviceRectangle_amber {
    margin: 3px 0 1px;
    width: 25px;
    min-height: 25px;
    background-color: rgb(220 174 84);
    border-radius: 13px;
    outline: 2px solid rgb(0 0 0 / 20%);
    outline-offset: -2px;
    filter: blur(2px);

    @include lg {
        margin: 0;
        width: 100%;
    }
}

.deviceRectangle_bad {
    margin: 3px 0 1px;
    width: 25px;
    min-height: 25px;
    background-color: rgb(235 13 13 / 69.8%);
    border-radius: 13px;
    outline: 2px solid rgb(0 0 0 / 20%);
    outline-offset: -2px;
    filter: blur(2px);

    @include lg {
        margin: 0;
        width: 100%;
    }
}

.deviceRectangle_blank {
    margin: 3px 0 1px;
    width: 25px;
    min-height: 25px;
    background-color: rgb(252 252 252);
    border-radius: 13px;
    outline: 2px solid rgb(0 0 0 / 20%);
    outline-offset: -2px;
    filter: blur(2px);

    @include lg {
        margin: 0;
        width: 100%;
    }
}

.deviceNameTitle {
    font: 400 24px/1.2 "Fira Sans Condensed", Helvetica, Arial, serif;
    color: rgb(0 0 0 / 80%);

    @include lg {
        width: 100%;
    }

    @include md {
        align-items: flex-start;
        font-size: 22px;
    }

    @include xxs {
        font-size: 20px;
    }
}

.trackerIdText {
    font: 400 24px/1.2 "Fira Sans Condensed", Helvetica, Arial, serif;
    color: rgb(63, 119, 187);

    @include lg {
        width: 100%;
    }

    @include md {
        align-items: flex-start;
        font-size: 22px;
    }

    @include xxs {
        font-size: 20px;
    }
}

.trackerIdTextRed {
    font: 400 24px/1.2 "Fira Sans Condensed", Helvetica, Arial, serif;
    color: rgb(235 13 13 / 69.8%);

    @include lg {
        width: 100%;
    }

    @include md {
        align-items: flex-start;
        font-size: 22px;
    }

    @include xxs {
        font-size: 20px;
    }
}

.statusTitle {
    font: 400 24px/1.2 "Fira Sans Condensed", Helvetica, Arial, serif;
    color: rgb(0 0 0 / 80%);

    @include lg {
        width: 100%;
    }

    @include md {
        align-items: flex-start;
        font-size: 22px;
    }

    @include xxs {
        font-size: 20px;
    }
}

.statusTitlePlot {
    font: 400 24px/1.2 "Fira Sans Condensed", Helvetica, Arial, serif;
    color: rgb(0 0 0 / 80%);
    padding-top: 20px;

    @include lg {
        width: 100%;
    }

    @include md {
        align-items: flex-start;
        font-size: 22px;
    }

    @include xxs {
        font-size: 20px;
    }
}

.statusText_good {
    font: 400 24px/1.2 "Fira Sans Condensed", Helvetica, Arial, serif;
    color: rgb(77 183 82 / 69.8%);

    @include lg {
        width: 100%;
    }

    @include md {
        align-items: flex-start;
        font-size: 22px;
    }

    @include xxs {
        font-size: 20px;
    }
}

.statusText_amber {
    font: 400 24px/1.2 "Fira Sans Condensed", Helvetica, Arial, serif;
    color: rgb(220 174 84);

    @include lg {
        width: 100%;
    }

    @include md {
        align-items: flex-start;
        font-size: 22px;
    }

    @include xxs {
        font-size: 20px;
    }
}

.statusText_bad {
    font: 400 24px/1.2 "Fira Sans Condensed", Helvetica, Arial, serif;
    color: rgb(235 13 13 / 69.8%);

    @include lg {
        width: 100%;
    }

    @include md {
        align-items: flex-start;
        font-size: 22px;
    }

    @include xxs {
        font-size: 20px;
    }
}

.etaTitle {
    font: 400 24px/1.2 "Fira Sans Condensed", Helvetica, Arial, serif;
    color: rgb(0 0 0 / 80%);

    @include lg {
        width: 100%;
    }

    @include md {
        align-items: flex-start;
        font-size: 22px;
    }

    @include xxs {
        font-size: 20px;
    }
}

.etaText_good {
    font: 400 24px/1.2 "Fira Sans Condensed", Helvetica, Arial, serif;
    color: rgb(130 204 133);
    letter-spacing: -0.7px;

    @include lg {
        width: 100%;
    }

    @include md {
        align-items: flex-start;
        font-size: 22px;
    }

    @include xxs {
        font-size: 20px;
    }
}

.etaText_amber {
    font: 400 24px/1.2 "Fira Sans Condensed", Helvetica, Arial, serif;
    color: rgb(220 174 84);
    letter-spacing: -0.7px;

    @include lg {
        width: 100%;
    }

    @include md {
        align-items: flex-start;
        font-size: 22px;
    }

    @include xxs {
        font-size: 20px;
    }
}

.etaText_bad {
    font: 400 24px/1.2 "Fira Sans Condensed", Helvetica, Arial, serif;
    color: rgb(235 13 13 / 69.8%);
    letter-spacing: -0.7px;

    @include lg {
        width: 100%;
    }

    @include md {
        align-items: flex-start;
        font-size: 22px;
    }

    @include xxs {
        font-size: 20px;
    }
}

.temperatureTitle {
    font: 400 24px/1.2 "Fira Sans Condensed", Helvetica, Arial, serif;
    color: rgb(0 0 0 / 80%);

    @include lg {
        width: 100%;
    }

    @include md {
        align-items: flex-start;
        font-size: 22px;
    }

    @include xxs {
        font-size: 20px;
    }
}

.temperatureText_good {
    font: 400 24px/1.2 "Fira Sans Condensed", Helvetica, Arial, serif;
    color: rgb(130 204 133);
    letter-spacing: -0.4px;

    @include lg {
        width: 100%;
    }

    @include md {
        align-items: flex-start;
        font-size: 22px;
    }

    @include xxs {
        font-size: 20px;
    }
}

.temperatureText_bad {
    font: 400 24px/1.2 "Fira Sans Condensed", Helvetica, Arial, serif;
    color: rgb(235 13 13 / 69.8%);
    letter-spacing: -0.4px;

    @include lg {
        width: 100%;
    }

    @include md {
        align-items: flex-start;
        font-size: 22px;
    }

    @include xxs {
        font-size: 20px;
    }
}

.humidityTitle {
    font: 400 24px/1.2 "Fira Sans Condensed", Helvetica, Arial, serif;
    color: rgb(0 0 0 / 80%);

    @include lg {
        width: 100%;
    }

    @include md {
        align-items: flex-start;
        font-size: 22px;
    }

    @include xxs {
        font-size: 20px;
    }
}

.humidityText_good {
    font: 400 24px/1.2 "Fira Sans Condensed", Helvetica, Arial, serif;
    color: rgb(130 204 133);

    @include lg {
        width: 100%;
    }

    @include md {
        align-items: flex-start;
        font-size: 22px;
    }

    @include xxs {
        font-size: 20px;
    }
}

.humidityText_bad {
    font: 400 24px/1.2 "Fira Sans Condensed", Helvetica, Arial, serif;
    color: rgb(235 13 13 / 69.8%);

    @include lg {
        width: 100%;
    }

    @include md {
        align-items: flex-start;
        font-size: 22px;
    }

    @include xxs {
        font-size: 20px;
    }
}

.batteryTitle {
    font: 400 24px/1.2 "Fira Sans Condensed", Helvetica, Arial, serif;
    color: rgb(0 0 0 / 80%);

    @include lg {
        width: 100%;
    }

    @include md {
        align-items: flex-start;
        font-size: 22px;
    }

    @include xxs {
        font-size: 20px;
    }
}

.batteryText_good {
    font: 400 24px/1.2 "Fira Sans Condensed", Helvetica, Arial, serif;
    color: rgb(130 204 133);

    @include lg {
        width: 100%;
    }

    @include md {
        align-items: flex-start;
        font-size: 22px;
    }

    @include xxs {
        font-size: 20px;
    }
}

.batteryText_amber {
    font: 400 24px/1.2 "Fira Sans Condensed", Helvetica, Arial, serif;
    color: rgb(220 174 84);

    @include lg {
        width: 100%;
    }

    @include md {
        align-items: flex-start;
        font-size: 22px;
    }

    @include xxs {
        font-size: 20px;
    }
}

.batteryText_bad {
    font: 400 24px/1.2 "Fira Sans Condensed", Helvetica, Arial, serif;
    color: rgb(235 13 13 / 69.8%);

    @include lg {
        width: 100%;
    }

    @include md {
        align-items: flex-start;
        font-size: 22px;
    }

    @include xxs {
        font-size: 20px;
    }
}

.lastFlexWrapperImage {
    position: absolute;
    top: 4px;
    right: 1px;
    width: 24px;
    cursor: pointer;
    opacity: 0.4;
}

.lastFlexWrapperImageRefresh {
    position: absolute;
    top: 52px;
    right: 4px;
    width: 24px;
    cursor: pointer;
    opacity: 0.4;
}

.btnCreateTRK {
    display: flex;
    justify-content: center;
    min-width: 120px;
    font: 400 16px/1.5 "Noto Sans", Helvetica, Arial, serif;
    color: rgb(255 255 255);
    text-align: center;
    background-color: rgb(76 146 230);
    border-radius: 8px;
    padding: 16px 8px;
    cursor: pointer;
}

.btnCreateTRK:hover {
    background: #529ffb;
    color: #fff;
}